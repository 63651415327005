import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import SwipeableViews from 'react-swipeable-views';
import styled from 'styled-components';
import { InfoCircle } from 'styled-icons/boxicons-regular/InfoCircle';

import Layout from '../components/layout';
import SEO from '../components/seo';
import Promotion from '../components/Promotion';

const Main = styled.main`
  max-width: 960px;
  margin: 0 auto;
  padding-left: 15px;
  padding-right: 15px;
`;

const StyledAppBar = styled(AppBar)`
  background-color: #2e2b2d;
  color: #fafafa;
`;

const StyledTabs = styled(Tabs)`
  .MuiTabs-indicator {
    background-color: #f1c524;
  }

  .MuiTabs-scrollButtons {
    color: #f1c524;
  }

  /* @media (min-width: 768px) {
    .MuiTabs-flexContainer {
      justify-content: center;
      Only use if the number of tab don't need to scroll 
    }
  } */
`;

const Menu = styled.main`
  padding: 10px;

  .menu-title {
    color: #f1c524;
    text-align: center;
    font-family: 'Yellowtail', cursive;
    font-weight: 700;
    font-size: 2.125rem;
    margin: 25px auto;
  }

  .menu-obs {
    p {
      font-size: 0.875rem;
      color: #ababab;
      font-weight: 600;
      text-align: center;
      margin-bottom: 30px;
    }
  }

  @media (min-width: 768px) {
    max-width: 600px;
    margin: 0 auto;
  }
`;

const Item = styled.div`
  margin-bottom: 40px;
  color: #fafafa;

  .title {
    text-transform: uppercase;
    font-weight: 400;
  }

  .price {
    font-size: 20px;
    color: #f1c524;
    font-weight: 400;
  }

  .desc {
    font-size: 0.875rem;
    color: #ababab;
    font-weight: 400;
  }

  .item-separator {
    border-bottom: 1px dotted rgba(255, 255, 255, 0.25);
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
  }
`;

const Bordas = () => (
  <>
    <h1 className="menu-title">Bordas</h1>

    <Item>
      <div className="item-separator">
        <h4 className="title">Borda Tradicional</h4>

        <h4 className="price">R$14,00</h4>
      </div>

      <p className="desc">
        Cheddar, Catupiry, Mussarela, Cream Cheese, Creme de Avelã, Leite Ninho,
        Chocolate ao Leite ou Chocolate Branco.
      </p>
    </Item>

    <Item>
      <div className="item-separator">
        <h4 className="title">Borda formato Pan</h4>

        <h4 className="price">R$19,00</h4>
      </div>

      <p className="desc">Cream Cheese, Mussarela ou Cheddar.</p>
    </Item>

    <Item>
      <div className="item-separator">
        <h4 className="title">Borda formato Camarão</h4>

        <h4 className="price">R$19,00</h4>
      </div>

      <p className="desc">Cream Cheese, Mussarela ou Cheddar.</p>
    </Item>

    <Item>
      <div className="item-separator">
        <h4 className="title">Borda formato Vulcão</h4>

        <h4 className="price">R$19,00</h4>
      </div>

      <p className="desc">
        Cheddar, Catupiry, Mussarela, Cream Cheese, Creme de Avelã, Leite Ninho,
        Chocolate ao Leite ou Chocolate Branco.
      </p>
    </Item>
  </>
);

const IndexPage = () => {
  const [value, setValue] = React.useState(0);

  function handleChange(event, newValue) {
    setValue(newValue);
  }

  function handleChangeIndex(index) {
    setValue(index);
  }

  return (
    <Layout>
      <SEO
        title="Cardápio"
        keywords={[
          `esfihas`,
          `esfihas doces`,
          `pizzas`,
          `pizzas doces`,
          `sucos`,
          `bebidas`,
          `disk entregas`,
        ]}
      />
      <Main>
        <Promotion />

        <StyledAppBar
          position="sticky"
          color="default"
          square={false}
          elevation={0}
        >
          <StyledTabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="inherit"
            variant="scrollable"
            scrollButtons="on"
          >
            <Tab label="Esfihas" />
            <Tab label="Esfihas Doces" />
            <Tab label="Pizzas" />
            <Tab label="Pizzas Doces" />
            <Tab label="Adicionais" />
            <Tab label="Bebidas" />
          </StyledTabs>
        </StyledAppBar>

        <SwipeableViews
          index={value}
          onChangeIndex={handleChangeIndex}
          animateHeight
        >
          <Menu>
            <h1 className="menu-title">Esfihas</h1>

            <Item>
              <div className="item-separator">
                <h4 className="title">01 - À Moda</h4>

                <h4 className="price">R$6,00</h4>
              </div>

              <p className="desc">Carne Moída com Mussarela.</p>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="title">02 - À Moda da Casa</h4>

                <h4 className="price">R$7,00</h4>
              </div>

              <p className="desc">
                Frango, Catupiry, Mussarela, Calabresa e Bacon.
              </p>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="title">03 - Abobrinha</h4>

                <h4 className="price">R$6,00</h4>
              </div>

              <p className="desc">Mussarela, Abobrinha e Parmesão.</p>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="title">04 - Bacon</h4>

                <h4 className="price">R$6,00</h4>
              </div>

              <p className="desc">Mussarela e Bacon.</p>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="title">05 - Berinjela</h4>

                <h4 className="price">R$6,00</h4>
              </div>

              <p className="desc">Mussarela, Berinjela e Parmesão.</p>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="title">06 - Brócolis</h4>

                <h4 className="price">R$6,00</h4>
              </div>

              <p className="desc">Mussarela, Brócolis, Bacon e Alho Frito.</p>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="title">07 - Carne</h4>

                <h4 className="price">R$5,00</h4>
              </div>

              <p className="desc">
                Carne Moída Temperada com Tomates e Cebola.
              </p>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="title">08 - Calabresa</h4>

                <h4 className="price">R$5,00</h4>
              </div>

              <p className="desc">Calabresa Moída.</p>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="title">9 - Calabresa 2</h4>

                <h4 className="price">R$7,00</h4>
              </div>

              <p className="desc">
                Calabresa Moída e Temperada com Tomate e Cebola.
              </p>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="title">10 - Costela</h4>

                <h4 className="price">R$8,00</h4>
              </div>

              <p className="desc">
                Costela, Catupiry, Mussarela, Tomate, Barbecue e Cebola.
              </p>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="title">11 - Cupim</h4>

                <h4 className="price">R$8,00</h4>
              </div>

              <p className="desc">
                Cupim, Catupiry, Mussarela, Tomate, Barbecue e Cebola.
              </p>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="title">12 - Escarola</h4>

                <h4 className="price">R$6,00</h4>
              </div>

              <p className="desc">Escarola e Mussarela.</p>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="title">13 - Esfihão</h4>

                <h4 className="price">R$6,00</h4>
              </div>

              <p className="desc">Presunto e Queijo.</p>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="title">14 - Frango com Catupiry</h4>

                <h4 className="price">R$6,00</h4>
              </div>

              <p className="desc">Frango com Catupiry</p>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="title">15 - Frango Especial</h4>

                <h4 className="price">R$7,00</h4>
              </div>

              <p className="desc">
                Frango Temperado com Especiarias da Casa, Catupiry e Tomate
                Cereja.
              </p>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="title">16 - FranPalha</h4>

                <h4 className="price">R$7,00</h4>
              </div>

              <p className="desc">Frango, Bacon, Cheddar e Batata Palha.</p>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="title">17 - Lombo</h4>

                <h4 className="price">R$6,00</h4>
              </div>

              <p className="desc">Lombo, Cebola e Catupiry.</p>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="title">18 - Napolitana</h4>

                <h4 className="price">R$6,00</h4>
              </div>

              <p className="desc">Mussarela, Tomate e Parmesão.</p>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="title">19 - Mexicana</h4>

                <h4 className="price">R$7,00</h4>
              </div>

              <p className="desc">
                Mussarela, Calabresa fatiada, Pimentão, Bacon, Tomate, Parmesão
                e Alho frito.
              </p>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="title">20 - Milho</h4>

                <h4 className="price">R$6,00</h4>
              </div>

              <p className="desc">Mussarela, Milho e Bacon.</p>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="title">21 - 4 Queijos</h4>

                <h4 className="price">R$6,00</h4>
              </div>

              <p className="desc">Mussarela, Catupiry, Provolone e Parmesão.</p>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="title">22 - Queijo</h4>

                <h4 className="price">R$5,00</h4>
              </div>

              <p className="desc">Mussarela</p>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="title">23 - Queijo 2</h4>

                <h4 className="price">R$7,00</h4>
              </div>

              <p className="desc">Queijo Temperado com Especiarias da Casa.</p>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="title">24 - Palmito</h4>

                <h4 className="price">R$6,00</h4>
              </div>

              <p className="desc">Mussarela e Palmito.</p>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="title">25 - Peperoni</h4>

                <h4 className="price">R$7,00</h4>
              </div>

              <p className="desc">Mussarela e Peperoni.</p>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="title">26 - Philadelphia</h4>

                <h4 className="price">R$7,00</h4>
              </div>

              <p className="desc">Peito de Peru e Cream Cheese.</p>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="title">27 - Viena</h4>

                <h4 className="price">R$6,00</h4>
              </div>

              <p className="desc">Atum e Mussarela.</p>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="title">28 - Toscana</h4>

                <h4 className="price">R$6,00</h4>
              </div>

              <p className="desc">Mussarela e Calabresa Moída.</p>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="title">29 - Torresmo</h4>

                <h4 className="price">R$7,00</h4>
              </div>

              <p className="desc">Mussarela, Torresmo e Bacon.</p>
            </Item>
          </Menu>

          <Menu>
            <h1 className="menu-title">Esfihas Doces</h1>

            <Item>
              <div className="item-separator">
                <h4 className="title">30 - Abacaxi Nevado</h4>

                <h4 className="price">R$7,00</h4>
              </div>

              <p className="desc">Mussarela, Abacaxi, Mel e Canela.</p>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="title">31 - Banana Nevada</h4>

                <h4 className="price">R$7,00</h4>
              </div>

              <p className="desc">
                Banana, Leite Condensado, Canela e Chocolate branco.
              </p>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="title">32 - Brigadeiro</h4>

                <h4 className="price">R$7,00</h4>
              </div>

              <p className="desc">Chocolate ao Leite e Granulado.</p>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="title">33 - Bueno</h4>

                <h4 className="price">R$7,00</h4>
              </div>

              <p className="desc">Chocolate, Avelã e Castanhas.</p>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="title">34 - Chocolaka</h4>

                <h4 className="price">R$7,00</h4>
              </div>

              <p className="desc">Chocolate Branco.</p>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="title">35 - Confete</h4>

                <h4 className="price">R$7,00</h4>
              </div>

              <p className="desc">Chocolate e Confete.</p>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="title">36 - Kit Kat</h4>

                <h4 className="price">R$7,00</h4>
              </div>

              <p className="desc">(Recheio a escolher).</p>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="title">37 - Mesclada</h4>

                <h4 className="price">R$7,00</h4>
              </div>

              <p className="desc">Escolha o sabor.</p>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="title">38 - Morango</h4>

                <h4 className="price">R$7,00</h4>
              </div>

              <p className="desc">Escolha o sabor.</p>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="title">39 - Ninho</h4>

                <h4 className="price">R$7,00</h4>
              </div>

              <p className="desc">Creme de Leite Ninho.</p>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="title">40 - Prestígio</h4>

                <h4 className="price">R$7,00</h4>
              </div>

              <p className="desc">Chocolate, Coco Ralado e Leite Condensado.</p>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="title">41 - Romeu e Julieta</h4>

                <h4 className="price">R$7,00</h4>
              </div>

              <p className="desc">Mussarela e Goiabada.</p>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="title">42 - Sensação</h4>

                <h4 className="price">R$7,00</h4>
              </div>

              <p className="desc">
                Creme de Abacaxi com Creme de Ninho e Creme de Morango (ou sabor
                a escolher).
              </p>
            </Item>

            <div className="menu-obs">
              <p>
                <InfoCircle size={26} /> Consulte valores e ingredientes de
                acréscimos.
              </p>
            </div>
          </Menu>

          <Menu>
            <h1 className="menu-title">Pizzas</h1>

            <div className="menu-obs">
              <p>
                <InfoCircle size={23} /> Todas nossas pizzas contém 8 pedaços.
              </p>
            </div>

            <Item>
              <div className="item-separator">
                <h4 className="title">01 - Abobrinha</h4>

                <h4 className="price">R$48,00</h4>
              </div>

              <p className="desc">
                Mussarela, Abobrinha, Parmesão, Azeitonas e Orégano.
              </p>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="title">02 - Atum</h4>

                <h4 className="price">R$51,00</h4>
              </div>

              <p className="desc">
                Atum, Mussarela, Palmito, Bacon, Azeitonas e Orégano.
              </p>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="title">03 - Americana</h4>

                <h4 className="price">R$49,00</h4>
              </div>

              <p className="desc">
                Mussarela, Presunto, Palmito, Ervilhas, Milho, Bacon, Azeitonas
                e Orégano.
              </p>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="title">04 - À Moda da Casa</h4>

                <h4 className="price">R$54,00</h4>
              </div>

              <p className="desc">
                Frango, Catupiry, Mussarela, Calabresa, Bacon, Azeitonas e
                Orégano.
              </p>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="title">05 - À Moda do Pizzaiolo</h4>

                <h4 className="price">R$51,00</h4>
              </div>

              <p className="desc">
                Lombo Canadense, Frango, Mussarela, Champignon e Palmito.
              </p>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="title">06 - Ariela</h4>

                <h4 className="price">R$48,00</h4>
              </div>

              <p className="desc">
                Lombo Canadense, Calabresa, Palmito, Bacon, Azeitonas e Orégano.
              </p>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="title">07 - Bacon</h4>

                <h4 className="price">R$48,00</h4>
              </div>

              <p className="desc">
                Mussarela, Bacon, Cebola, Tomate, Orégano e Azeitona.
              </p>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="title">08 - Baianinha</h4>

                <h4 className="price">R$48,00</h4>
              </div>

              <p className="desc">
                Calabresa Moída, Mussarela, Pimenta Calabresa, Pimenta Biquinho,
                Tomate, Azeitona e Orégano.
              </p>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="title">09 - Berinjela</h4>

                <h4 className="price">R$48,00</h4>
              </div>

              <p className="desc">
                Mussarela, Berinjela Gratinada, Parmesão e Orégano.
              </p>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="title">10 - Benvenutte</h4>

                <h4 className="price">R$51,00</h4>
              </div>

              <p className="desc">
                Presunto, Mussarela, Calabresa, Ovos, Azeitonas, Orégano e Alho
                Frito.
              </p>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="title">11 - Brócolis</h4>

                <h4 className="price">R$48,00</h4>
              </div>

              <p className="desc">
                Mussarela, Brócolis, Catupiry, Bacon, Azeitonas, Orégano e Alho
                Frito.
              </p>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="title">12 - Caipira</h4>

                <h4 className="price">R$51,00</h4>
              </div>

              <p className="desc">
                Mussarela, Frango, Tomate, Milho, Cebola, Azeitonas e Orégano.
              </p>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="title">13 - Calabresa 1</h4>

                <h4 className="price">R$45,00</h4>
              </div>

              <p className="desc">Calabresa, Cebola, Azeitonas e Orégano.</p>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="title">14 - Calabresa 2</h4>

                <h4 className="price">R$51,00</h4>
              </div>

              <p className="desc">
                Mussarela, Calabresa, Cebola, Azeitonas e Orégano.
              </p>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="title">15 - Calabresa 3</h4>

                <h4 className="price">R$51,00</h4>
              </div>

              <p className="desc">
                Catupiry, Calabresa, Cebola, Azeitonas e Orégano.
              </p>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="title">16 - Canadense</h4>

                <h4 className="price">R$56,00</h4>
              </div>

              <p className="desc">
                Lombo, Requeijão, Mussarela, Milho, Bacon e Ovo.
              </p>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="title">17 - Costela</h4>

                <h4 className="price">R$62,00</h4>
              </div>

              <p className="desc">
                Costela, Catupiry, Mussarela, Tomate, Barbecue e Cebola.
              </p>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="title">18 - Cupim</h4>

                <h4 className="price">R$62,00</h4>
              </div>

              <p className="desc">
                Cupim, Catupiry, Mussarela, Tomate, Barbecue e Cebola.
              </p>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="title">19 - Da Avó</h4>

                <h4 className="price">R$48,00</h4>
              </div>

              <p className="desc">
                Lombo, Mussarela, Palmito, Azeitonas e Orégano.
              </p>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="title">20 - Escarola</h4>

                <h4 className="price">R$49,00</h4>
              </div>

              <p className="desc">Escarola, Mussarela, Azeitonas e Orégano.</p>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="title">21 - FranBacon</h4>

                <h4 className="price">R$54,00</h4>
              </div>

              <p className="desc">
                Mussarela, Frango em Cubos, Bacon, Tomate Cereja, Azeitonas e
                Orégano.
              </p>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="title">22 - Frango 1</h4>

                <h4 className="price">R$49,00</h4>
              </div>

              <p className="desc">Frango, Catupiry, Azeitonas e Orégano.</p>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="title">23 - Frango 2</h4>

                <h4 className="price">R$51,00</h4>
              </div>

              <p className="desc">Frango, Mussarela, Azeitonas e Orégano.</p>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="title">24 - Frango 3</h4>

                <h4 className="price">R$54,00</h4>
              </div>

              <p className="desc">
                Frango, Cream Cheese, Tomate Cereja, Mussarela, Azeitonas e
                Orégano.
              </p>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="title">25 - FranPalha</h4>

                <h4 className="price">R$54,00</h4>
              </div>

              <p className="desc">
                Mussarela, Frango, Bacon, Cheddar, Batata Palha, Azeitonas e
                Orégano.
              </p>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="title">26 - Francesa</h4>

                <h4 className="price">R$54,00</h4>
              </div>

              <p className="desc">
                Mussarela, Peito de Peru, Cebola, Tomate, Catupiry e Parmesão.
              </p>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="title">27 - Havaiana</h4>

                <h4 className="price">R$52,00</h4>
              </div>

              <p className="desc">
                Mussarela, Peito de Peru, Abacaxi, e Molho. (Escolher molho de
                tomate ou creme de leite).
              </p>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="title">28 - Jardineira</h4>

                <h4 className="price">R$52,00</h4>
              </div>

              <p className="desc">
                Presunto, Catupiry, Mussarela, Ovos, Bacon, Azeitonas e Orégano.
              </p>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="title">29 - Lombo</h4>

                <h4 className="price">R$48,00</h4>
              </div>

              <p className="desc">
                Lombo, Catupiry, Mussarela, Azeitonas e Orégano.
              </p>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="title">30 - Marguerita</h4>

                <h4 className="price">R$48,00</h4>
              </div>

              <p className="desc">
                Mussarela, Tomate, Parmesão, Manjericão, Azeitonas e Orégano.
              </p>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="title">31 - Milho</h4>

                <h4 className="price">R$48,00</h4>
              </div>

              <p className="desc">Mussarela, Milho, Bacon e Orégano.</p>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="title">32 - Mexicana 1</h4>

                <h4 className="price">R$50,00</h4>
              </div>

              <p className="desc">
                Mussarela, Calabresa, Pimentão, Bacon, Tomate, Parmesão,
                Azeitonas, Orégano e Alho Frito.
              </p>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="title">33 - Mexicana 2</h4>

                <h4 className="price">R$55,00</h4>
              </div>

              <p className="desc">
                Mussarela, Calabresa, Pimenta Calabresa, Palmito, Bacon,
                Peperoni, Tomate, Parmesão, Azeitonas, Orégano e Alho Frito.
              </p>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="title">34 - Mussarela</h4>

                <h4 className="price">R$45,00</h4>
              </div>

              <p className="desc">Mussarela, Tomate, Azeitonas e Orégano.</p>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="title">35 - Palmito</h4>

                <h4 className="price">R$48,00</h4>
              </div>

              <p className="desc">Mussarela, Palmito, Azeitonas e Orégano.</p>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="title">36 - Portuguesa</h4>

                <h4 className="price">R$49,00</h4>
              </div>

              <p className="desc">
                Mussarela, Presunto, Ervilhas, Ovos, Cebola, Azeitonas e
                Orégano.
              </p>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="title">37 - Peperoni 1</h4>

                <h4 className="price">R$51,00</h4>
              </div>

              <p className="desc">Mussarela, Peperoni, Orégano e Azeitonas.</p>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="title">38 - Peperoni 2</h4>

                <h4 className="price">R$55,00</h4>
              </div>

              <p className="desc">
                Cream Cheese, Mussarela, Peperoni, Orégano e Azeitonas.
              </p>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="title">39 - Philadelphia</h4>

                <h4 className="price">R$52,00</h4>
              </div>

              <p className="desc">
                Peito de Peru, Cream Cheese, Mussarela, Orégano e Azeitonas.
              </p>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="title">40 - Presunto e Queijo</h4>

                <h4 className="price">R$45,00</h4>
              </div>

              <p className="desc">
                Presunto, Mussarela, Tomate, Azeitonas e Orégano.
              </p>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="title">41 - Quatro Queijos</h4>

                <h4 className="price">R$51,00</h4>
              </div>

              <p className="desc">
                Mussarela, Catupiry, Provolone, Parmesão, Azeitonas e Orégano.
              </p>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="title">42 - Cinco Queijos</h4>

                <h4 className="price">R$55,00</h4>
              </div>

              <p className="desc">
                Mussarela, Catupiry, Provolone, Parmesão, Gorgonzola, Azeitonas
                e Orégano.
              </p>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="title">43 - Salame</h4>

                <h4 className="price">R$55,00</h4>
              </div>

              <p className="desc">
                Mussarela, Salame, Parmesão, Azeitonas e Orégano.
              </p>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="title">44 - Vegetariana</h4>

                <h4 className="price">R$51,00</h4>
              </div>

              <p className="desc">
                Escarola, Mussarela, Ervilha, Palmito, Milho, Azeitonas e
                Orégano.
              </p>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="title">45 - Texana</h4>

                <h4 className="price">R$65,00</h4>
              </div>

              <p className="desc">
                Molho, Mussarela, Alcatra, Cebola, Tomate e Azeitona.
              </p>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="title">46 - Torresmo</h4>

                <h4 className="price">R$52,00</h4>
              </div>

              <p className="desc">
                Mussarela, Bacon, Torresmo, Azeitonas e Orégano.
              </p>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="title">47 - Tropical Curry</h4>

                <h4 className="price">R$54,00</h4>
              </div>

              <p className="desc">
                Muçarela, presunto, abacaxi, banana, mel, pó em curry azeitonas
                e orégano.
              </p>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="title">48 - Pizza MK</h4>

                <h4 className="price">R$62,00</h4>
              </div>

              <p className="desc">Monte sua Pizza com até 5 ingredientes.</p>
            </Item>

            <div className="menu-obs">
              <p>
                <InfoCircle size={26} /> Todas nossas pizzas salgadas contém
                Molho.
              </p>
            </div>

            <Bordas />
          </Menu>

          <Menu>
            <h1 className="menu-title">Pizzas Doces</h1>

            <div className="menu-obs">
              <p>
                <InfoCircle size={23} /> Todas nossas pizzas contém 8 pedaços.
              </p>
            </div>

            <Item>
              <div className="item-separator">
                <h4 className="title">49 - Abacaxi Nevado</h4>

                <h4 className="price">R$49,00</h4>
              </div>

              <p className="desc" />
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="title">50 - Banana Nevada</h4>

                <h4 className="price">R$49,00</h4>
              </div>

              <p className="desc">Banana, Canela e Chocolate branco.</p>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="title">51 - Brigadeiro</h4>

                <h4 className="price">R$47,00</h4>
              </div>

              <p className="desc">Chocolate ao Leite e Granulado.</p>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="title">52 - Bueno</h4>

                <h4 className="price">R$49,00</h4>
              </div>

              <p className="desc" />
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="title">53 - Chocolaka</h4>

                <h4 className="price">R$47,00</h4>
              </div>

              <p className="desc">Chocolate Branco.</p>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="title">54 - Confete</h4>

                <h4 className="price">R$47,00</h4>
              </div>

              <p className="desc">Chocolate e Confete.</p>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="title">55 - Kit Kat</h4>

                <h4 className="price">R$49,00</h4>
              </div>

              <p className="desc">Escolha o Recheio.</p>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="title">56 - Mesclada</h4>

                <h4 className="price">R$47,00</h4>
              </div>

              <p className="desc">Chocolate Branco e Chocolate Preto.</p>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="title">57 - Morango</h4>

                <h4 className="price">R$49,00</h4>
              </div>

              <p className="desc">Escolha o sabor.</p>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="title">58 - Ninho</h4>

                <h4 className="price">R$47,00</h4>
              </div>

              <p className="desc">Creme de Leite Ninho.</p>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="title">59 - Prestígio</h4>

                <h4 className="price">R$47,00</h4>
              </div>

              <p className="desc">Chocolate, Coco Ralado e Leite Condensado.</p>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="title">60 - Romeu e Julieta</h4>

                <h4 className="price">R$49,00</h4>
              </div>

              <p className="desc">Mussarela e Goiabada.</p>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="title">61 - Sensação</h4>

                <h4 className="price">R$49,00</h4>
              </div>

              <p className="desc">
                Creme de Abacaxi com Creme de Ninho e Creme de Morango (Ou sabor
                a escolher).
              </p>
            </Item>

            <div className="menu-obs">
              <p>
                <InfoCircle size={26} /> Consulte valores e ingredientes de
                acréscimos.
              </p>
            </div>

            <Bordas />
          </Menu>

          <Menu>
            <Bordas />

            <h1 className="menu-title">Adicionais</h1>

            <Item>
              <div className="item-separator">
                <h4 className="title">Adicional na Pizza inteira</h4>

                <h4 className="price">R$10,00</h4>
              </div>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="title">Adicional na meia Pizza</h4>

                <h4 className="price">R$5,00</h4>
              </div>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="title">Adicional na Esfiha</h4>

                <h4 className="price">R$1,00</h4>
              </div>
            </Item>

            <div className="menu-obs">
              <p>
                <InfoCircle size={26} /> Consulte ingredientes de acréscimos
              </p>
            </div>
          </Menu>

          <Menu>
            <h1 className="menu-title">Bebidas</h1>

            <Item>
              <div className="item-separator">
                <h4 className="title">Suco Natural de Laranja 1L</h4>

                <h4 className="price">R$17,00</h4>
              </div>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="title">Suco Natural de Tangerina 1L</h4>

                <h4 className="price">R$17,00</h4>
              </div>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="title">Refrigerante 2L</h4>

                <h4 className="price">R$10,00</h4>
              </div>

              <p className="desc">Xereta, Tubaina, Itubaina ou Gengibirra</p>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="title">Refrigerante 2L Sprite</h4>

                <h4 className="price">R$15,00</h4>
              </div>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="title">Refrigerante 2L Pepsi</h4>

                <h4 className="price">R$14,00</h4>
              </div>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="title">
                  Refrigerante 1L Coca Cola ou Coca Zero
                </h4>

                <h4 className="price">R$10,00</h4>
              </div>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="title">Refrigerante 1,5L Guaraná Antarctica</h4>

                <h4 className="price">R$10,00</h4>
              </div>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="title">Refrigerante 1,5L Fanta Laranja</h4>

                <h4 className="price">R$10,00</h4>
              </div>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="title">Refrigerantes Lata Coca Cola</h4>

                <h4 className="price">R$6,00</h4>
              </div>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="title">Refrigerantes Lata Coca Zero</h4>

                <h4 className="price">R$6,00</h4>
              </div>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="title">Refrigerantes Lata Sprite</h4>

                <h4 className="price">R$6,00</h4>
              </div>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="title">Refrigerantes Lata Fanta Laranja</h4>

                <h4 className="price">R$6,00</h4>
              </div>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="title">Refrigerantes Lata Fanta Uva</h4>

                <h4 className="price">R$6,00</h4>
              </div>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="title">Refrigerantes Lata Fanta Maracujá</h4>

                <h4 className="price">R$6,00</h4>
              </div>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="title">H2Oh</h4>

                <h4 className="price">R$7,00</h4>
              </div>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="title">Água com Gás</h4>

                <h4 className="price">R$4,00</h4>
              </div>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="title">Água sem Gás</h4>

                <h4 className="price">R$3,00</h4>
              </div>
            </Item>

            <h1 className="menu-title">Cervejas</h1>

            <Item>
              <div className="item-separator">
                <h4 className="title">Cerveja Heineken 330ml</h4>

                <h4 className="price">R$10,00</h4>
              </div>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="title">Cerveja Corona 330ml</h4>

                <h4 className="price">R$10,00</h4>
              </div>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="title">Cerveja Budweiser 330ml</h4>

                <h4 className="price">R$10,00</h4>
              </div>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="title">Cerveja Heineken Lata</h4>

                <h4 className="price">R$7,00</h4>
              </div>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="title">Cerveja Brahma Duplo Malte Lata</h4>

                <h4 className="price">R$5,00</h4>
              </div>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="title">Cerveja Brahma Lata</h4>

                <h4 className="price">R$6,00</h4>
              </div>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="title">Cerveja Brahma 0% Lata</h4>

                <h4 className="price">R$6,00</h4>
              </div>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="title">Cerveja Crystal Lata</h4>

                <h4 className="price">R$5,00</h4>
              </div>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="title">Cerveja Império Lata</h4>

                <h4 className="price">R$6,00</h4>
              </div>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="title">Cerveja Skol Lata</h4>

                <h4 className="price">R$6,00</h4>
              </div>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="title">Cerveja Skol Puro Malte Lata</h4>

                <h4 className="price">R$6,00</h4>
              </div>
            </Item>
          </Menu>
        </SwipeableViews>
      </Main>
    </Layout>
  );
};

export default IndexPage;
